@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,900);

//colors
$red: #eb4034;
$white: #fff;
$titleColor: #fff;


h3{
  color: $white;
}
.homeMenuButtons .homeUserName{
  margin-bottom: 0px;
  font-size: calc(20px + 2vmin);
  color: $titleColor;
}
.homeMenuButtons .homeUserTitle{
  margin-top: 0px;
  
  color:$titleColor;
  font-size: calc(10px + 1vmin);
  color: white;
  
}
// Main wrap
.homeMenuButtons .containerPortal .buttons {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
}

// Button wrap
.homeMenuButtons .containerPortal { 
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
    text-align: center; 
    
    @media (min-width: 600px) {
        flex-direction: row;
        justify-content: space-between;
    }
}


.homeMenuButtons .containerPortal p {
  color: $white;
  font-size: 12px;
  
  @media(min-width: 600px) {
    left: 50%;
    position: absolute; 
    transform: translate(-50%, 0);
    top: 90%;
  }
  
  @media(max-height: 500px) {
    left: 0;
    position: relative;
    top: 0;
    transform: translate(0, 0);
  }
  
.homeMenuButtons .containerPortal a {
    background: rgba($white, 0);
    border-bottom: 1px solid;
    color: $white;
    line-height: 1.4;
    padding: .25em;
    text-decoration: none;
    
    &:hover {
      background: rgba($white, 1);
      color: $red;
    }
  }
}
//button styles


//default button
.homeMenuButtons .containerPortal .btnPortal {
  color: #fff;
  cursor: pointer;
  // display: block;
  font-size:16px;
  font-weight: 400;
  line-height: 45px;
  margin: 0 0 2em;
  max-width: 160px; 
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%; 
  min-width: 120px;
  transition: color .21s, font-size .21s, font-weight .21s;
  
//   @media(min-width: 400px) {
//     display: inline-block;
//     margin-right: 2.5em;
  
//     &:nth-of-type(even) {
//       margin-right: 0;
//     }
//   }
  
  @media(min-width: 600px) {
      
    margin: 0 1em 2em;
  
//     &:nth-of-type(even) {
//       margin-right: 2.5em;
//     }
    
//     &:nth-of-type(5) {
//       margin-right: 0;
//     }
    
  }
  
  &:hover { text-decoration: none; }
  
}

/////////////////////////////////
//button one
///////////////////////////////
.homeMenuButtons .containerPortal .btnPortal-1 {
  background: darken($red, 1.5%);
  font-weight: 100;
  
  svg {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0; 
    width: 100%; 
  }
  
  rect {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  }
}

.homeMenuButtons .containerPortal .btnPortal-1:hover {
  background: rgba($red, 0);
  font-weight: 900;
  letter-spacing: 1px;
  color: $red;
  rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

////////////////////////////
//button two
//////////////////////////
.homeMenuButtons .containerPortal .btnPortal-2 {
    letter-spacing: 0;
}

.homeMenuButtons .containerPortal .btnPortal-2:hover,
.homeMenuButtons .containerPortal .btnPortal-2:active {
  letter-spacing: 5px;
}

.homeMenuButtons .containerPortal .btnPortal-2:after,
.homeMenuButtons .containerPortal .btnPortal-2:before {
  backface-visibility: hidden;
  border: 1px solid rgba(#fff, 0);
  bottom: 0px;
  content: " ";
  display: block;
  margin: 0 auto;
  position: relative;
  transition: all 280ms ease-in-out;
  width: 0;
}

.homeMenuButtons .containerPortal .btnPortal-2:hover:after,
.homeMenuButtons .containerPortal .btnPortal-2:hover:before {
  backface-visibility: hidden;
  border-color: #fff;
  transition: width 350ms ease-in-out;
  width: 70%;
}

.homeMenuButtons .containerPortal .btnPortal-2:hover:before {
  bottom: auto;
  top: 0;
  width: 70%;
}

/////////////////////////////
//button -3 
///////////////////////////
.homeMenuButtons .containerPortal .btnPortal-3 {
  background: lighten($red, 3%);  
  border: 1px solid darken($red, 4%);
  box-shadow: 0px 2px 0 darken($red, 5%), 2px 4px 6px darken($red, 2%);
  font-weight: 900;
  letter-spacing: 1px;
  transition: all 150ms linear;
}

.homeMenuButtons .containerPortal .btnPortal-3:hover {
  background: darken($red, 1.5%);
  border: 1px solid rgba(#000, .05);
  box-shadow: 1px 1px 2px rgba(#fff, .2);
  color: lighten($red, 18%); 
  text-decoration: none;
  text-shadow: -1px -1px 0 darken($red, 9.5%);
  transition: all 250ms linear;
}

/////////////////////////////
//button-4 
///////////////////////////
.homeMenuButtons .containerPortal .btnPortal-4 {
  border: 1px solid;
  overflow: hidden;
  position: relative;
  
  span {
    z-index: 20;
  }
  
  &:after {
    background: #fff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
  }
}

.homeMenuButtons .containerPortal .btnPortal-4:hover {
  
  &:after {
    left: 120%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  }
}

/////////////////////////////
//button-5 
///////////////////////////
.homeMenuButtons .containerPortal .btnPortal-5 {
  border: 0 solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
  outline: 1px solid;
  outline-color: rgba(255, 255, 255, .5);
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
} 

.homeMenuButtons .containerPortal .btnPortal-5:hover {
  border: 1px solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388; 
}