.projContainer {
    margin-top: 40px;
}

.projectContainer {
    width: 100%;
}

.projectContainer h4 {
    color: #000;
}

.projectContainer a {
    text-decoration: none;
    color: #ea392d;
}

.imgContainer {
    width: 100%;
    text-align: center;
}

.projectContainer img {
    max-width: 100%;
    margin: 10px 0px 10px 0px;
}

.landingPage .projectContainer span {
    width: auto;
    border: 2px #000000 solid;
    border-radius: 50px;
    margin: 10px;
}

//for websiteProject
.headTitle {
    text-align: center;
    font-weight: 900;
}

.webProjects {
    margin-top: 40px;
    text-align: center;
}

.webProjects .webProjectWrap {
    overflow: hidden;
    margin-top: 20px;
}

.webProjects .img-wrap {
    overflow: hidden;
    height: 25vw;
    padding: 10px 10px 10px 10px;
    margin-top: 20px;

}

.webProjectWrap h4 {
    text-align: center;
}

.webProjectWrap a {
    text-decoration: none;
    color: #ea392d;
}

.webProjects span {
    font-size: 14px;
    text-align: right;
    margin: 4px;
    border: 1px #000 solid;
    padding: 2px 5px 2px 5px;
    border-radius: 10px;
}

.webProjectWrap p {
    margin-top: 15px;
    text-align: left;
}

//About
.aboutContainer {
    margin-top: 40px;
    margin-bottom: 40px;
}

.aboutContainer h3 {
    color: #000;
}

.aboutContainer .row p {
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: 300;
}

.codeDemo {
    max-width: 100%;
}

.aboutWrap {
    margin-top: 50px;
}

//Photos
.photosContainer {
    margin-top: 40px;
}

.imagePhoto {
    text-align: center;
    display: inline-block;
    margin: 20px;
}

.imagePhoto img {
    max-width: 390px;
}

//Design


.designContainer {
    margin-top: 40px;
}
.designContainer .designFigma{
    text-align: center;
}
.designContainer .designFigma iframe{
    width: 100%;
    height: 650px;
}
.designContainer .video-container {
    width: 100%;
    pointer-events: none;
}
@media only screen and (max-width: 981px) {
    .designContainer .designFigma iframe{
        height: 400px;
    }
  }



//404

.errorContainer .errorWraps {
    width: 100%;
    text-align: center;

}

.errorContainer .errorWrap {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.errorContainer {
    width: 100%;
}


.divider {
    margin-bottom: 50px;
    position: relative;
    margin-top: 50px;
    height: 1px;
}

.div-transparent:before {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;
    background-image: linear-gradient(to right, transparent, rgb(48, 49, 51), transparent);
}

.div-arrow-down:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -9px;
    left: calc(50% - 9px);
    width: 18px;
    height: 18px;
    background-color: #ea392d;
    border: 1px solid rgb(48, 49, 51);
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px white,
        0 0 0 4px white;
}