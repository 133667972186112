@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,900;1,100;1,300;1,400;1,900&display=swap');


html,
body {
   margin:0;
   padding:0;
   height:100%;
}
#root{
    height: 100%;
}
.bg {
    min-height: 20vh;
    background-image: url('https://farm5.static.flickr.com/4230/35521598222_9ea0c4e5d5_c.jpg');
    background-size: cover;
    background-position: bottom center;
}

.sticky-top {
    transition: all 0.25s ease-in;
}

/* style for when sitcky is applied */
.stuck .navbar.sticky-top {
    background-color: #222 !important;
    padding-top: .8rem !important;
    padding-bottom: .8rem !important;
}


.dashboard-title{
    font-size: 3em;
    text-align: center;
    font-weight: 900;
}
.dashboard-subtitle{
    text-align: center;
}

.landingPage .bg-nav-mod{
   background: linear-gradient(-85deg, #363636, #000000, #363636, #2e2e2e);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
.landingPage .header{
    overflow: hidden;
}
.landingPage{
    min-height: 100%;
}
.dashboardWrap{
    padding-bottom: 72px;
    font-family: Roboto;
}
.dashboardFooter{
    position: relative;
    margin-top: -72px!important;
    /* negative value of footer height */
    height: 72px;
    clear: both;
    background-color: #000000;
}
.footerURL{
    text-transform: uppercase;
    text-decoration: none;
}
.dashboardLink{
    padding-left: 10px;
    font-size: 24px;
    color:#fff;
    -webkit-transition: color .51s; 
        transition: color .51s; 
}
.dashboardLink:hover{
    color:#ea392d;
}

@media only screen and (max-width: 767px) {
    .dashboardFooter{
        height: 120px;
    }
  }



@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

