$avatar: "../avatar.png";


/*
.homeAvatar{
    background-image: url($avatar);
    background-color: red;
}
*/
.fixdiv {
    position: fixed;
    color:red;
    top: 1em;
    right: 1em;
    z-index: 1;
}
.mainAvatar{
    border-radius: 50%;
    width: auto;
    height: 220px;
    border: 2px #ffffff solid;

}
.innerContent{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*width: min(92%, 600px);*/
}
.homeAvatar{
    position: relative;
    min-height: 332px;
}
.rotatingImage{
    position: absolute;
    z-index: -1000;
}

.App-avatar{
    animation: App-logo-spin infinite 10s linear;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(63deg) brightness(104%) contrast(104%);
}